<template>
    <div>
        <div class="row row-item">
            <!--<div class="col-sm-2">
                <div class="product-image-list-item"
                     :style="{ backgroundImage: 'url(\'' + getPhoto(cartItem.photo) + '\')' }">
                </div>
            </div>
            <div class="col-sm-10">
                <div>{{ cartItem.code }}</div>
                <div>{{ cartItem.title }}</div>
                <div>ΤΙΜΗ: {{ cartItem.price }}, ΤΕΜΑΧΙΑ: {{ cartItem.qty }} </div>
            </div>-->
            <div class="item-image">
                <div class="product-image-list-item"
                     :style="{ backgroundImage: 'url(\'' + getPhoto(cartItem.photo) + '\')' }">
                </div>
            </div>
            <div class="item-text">
                <div>{{ cartItem.code }}</div>
                <div class="item-text-title">{{ cartItem.title }}</div>
                <div class="item-price">ΤΙΜΗ: {{ cartItem.price }}, ΤΕΜΑΧΙΑ: {{ cartItem.qty }} </div>
            </div>
        </div>
    </div>
    
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        name: "ProductListItem",
        props: ["cartItem"],
        methods: {
            ...mapActions(["addCartItem"]),
        },
    };
</script>

<style>
    .item-text-title {
        max-width:340px;
        overflow:hidden;
    }
    .item-price {
        position: absolute;
        bottom: 0;
    }
    .item-image, .item-text {
        display: inline-block;
    }
    .item-text {
        padding-left:20px;
        font-weight:bold;
        position:relative;
    }
    .row-item {
        margin-bottom: 20px;
        margin-left:8px;
    }
    .product-image-list-item {
        width: 80px;
        height: 80px;
        position: relative;
        cursor: pointer;
        /*background-size: auto 100%;*/
        background-size: cover;
        background-position: center center;
    }
    @media screen and (max-width: 580px) {
        .item-text {
            font-size: 11px !important;
        }
        .product-image-list-item {
            width: 60px;
            height: 60px;
        }
    }
</style>