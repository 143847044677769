import axios from 'axios';

const state = {
    cartItems: []
}

const mutations = {
    UPDATE_CART_ITEMS(state, payload) {
        state.cartItems = payload;
    }
}

const actions = {
    getCartItems({ commit }, id) {
        //console.log('cart item', id);
        if (id != undefined) {
            axios.get('/api/cart/' + id).then((response) => {
                commit('UPDATE_CART_ITEMS', response.data)
            });
        }
    },
    addCartItem({ commit }, cartItem) {
        return new Promise((resolve) => {
            axios.post('/api/cart', cartItem).then((response) => {
                //console.log(response);
                if (response.data.success) {
                    commit('UPDATE_CART_ITEMS', response.data.object.value)
                }
                resolve(response);
            });
            //axios.post('/api/cart/stock/check', cartItem).then((responseStock) => {
            //    // check stock
            //    if (responseStock.data == 1) {
            //        axios.post('/api/cart', cartItem).then((response) => {
            //            commit('UPDATE_CART_ITEMS', response.data)
            //            resolve(responseStock);
            //        });
            //    }
            //    else {
            //        resolve(responseStock);
            //        //console.log('Missing qty');
            //    }
            //});
        })
    },
    removeCartItem({ commit }, cartItem) {
        //console.log(cartItem);
        //axios.delete('/api/cart', cartItem).then((response) => {
        axios.post('/api/cart/delete', cartItem).then((response) => {
            commit('UPDATE_CART_ITEMS', response.data)
        });
    },
    //sendCartOrder({ commit }, order) {
    //    //console.log(cartItem);
    //    axios.post('/api/cart/send', order).then((response) => {
    //        commit('UPDATE_CART_ITEMS', response.data)
    //    });
    //},
    removeAllCartItems({ commit }, id) {
        //console.log('delete', this.getUID);
        if (confirm("Are you sure to delete ?")) {
            axios.delete('/api/cart/delete/all/' + id).then((response) => {
                commit('UPDATE_CART_ITEMS', response.data)
            });
        }

    },
    closeOrder({ commit }) {
        //console.log('delete', this.getUID);
        var cartItems = [];
        commit('UPDATE_CART_ITEMS', cartItems)
    }
}

const getters = {
    cartItems: state => state.cartItems,
    cartTotal: state => {
        return state.cartItems.reduce((acc, cartItem) => {
            return (cartItem.qty * cartItem.price) + acc;
        }, 0).toFixed(2);
    },
    cartQuantity: state => {
        return state.cartItems.reduce((acc, cartItem) => {
            return cartItem.qty + acc;
        }, 0);
    }
}

const cartModule = {
    state,
    mutations,
    actions,
    getters
}
export default cartModule;