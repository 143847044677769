//https://www.digitalocean.com/community/tutorials/vuejs-vue-autocomplete-component

<template>
    <div class="search-frame">
        <div class="search-frame-buttons">
            <div class="search-buttons">
                <div id="mdiv" class="white-close search-close" @click="onCloseButton">
                    <div class="mdiv">
                        <div class="md"></div>
                    </div>
                </div>
                <div class="autocomplete green-bg-text">
                    <input class="search-input"
                           v-model="search"
                           @input="onChange"
                           @keydown.down="onArrowDown"
                           @keydown.up="onArrowUp"
                           @keydown.enter="onEnter"
                           ref="searchInput" placeholder="SEARCH" type="text" />

                    <ul v-show="isOpen"
                        class="autocomplete-results">
                        <li v-if="isLoading"
                            class="loading">
                            Loading results...
                        </li>
                        <li v-else
                            v-for="(result, i) in results"
                            :key="i"
                            @click="setResult(result)"
                            class="autocomplete-result"
                            :class="{ 'is-active': i === arrowCounter }">
                            {{formatName(result)}}
                            <!--{{result.brand }} > {{ result.name }}-->
                        </li>
                    </ul>
                </div>
                <div class="search-notes">χρηση πληθυντικου π.χ. καναπεδες</div>
                <!--<div class="text-align-right">
                    <span @click="focusInputSearch()" class="button-home">
                        <img src="../assets/narrow.svg" />
                    </span>
                </div>-->
            </div>
        </div>
    </div>
</template>


<script>
    import axios from 'axios'
    export default {
        name: 'Search',
        props: {
            //items: {
            //    type: Array,
            //    required: false,
            //    default: () => [],
            //},
            //isAsync: {
            //    type: Boolean,
            //    required: false,
            //    default: false,
            //},
        },
        data() {
            return {
                showSearch: false,
                search: '',
                isOpen: true,
                results: [],
                arrowCounter: -1,
                isAsync: false,
                isLoading: false,
                items: []
            }
        },
        methods: {
            getCategory() {
                axios.get('/api/category/child').then((response) => {
                    this.items = response.data;
                    //this.isAsync = false;
                })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            formatName(item) {

                var brand = "Prop N Roll";

                if (item.brand != null) brand = item.brand;

                return brand + " > " + item.name;

            },
            onCloseButton() {
                this.$emit('close-clicked')
            },
            focusInputSearch() {
                this.showSearch = true;
            },
            replaceTonos(s) {
                return s.replace('ά', 'α').replace('έ', 'ε').replace('ή', 'η').replace('ί', 'ι').replace('ό', 'ο')
                    .replace('ώ', 'ω').replace('ύ', 'υ');
            },
            filterResults() {
                //console.log(this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1));
                this.results = this.items.filter(item => item.name.toLowerCase().indexOf(" " + this.replaceTonos(this.search.toLowerCase())) > -1);
            },
            setResult(result) {
                this.search = result;
                this.isOpen = false;
                this.onCloseButton();

                if (this.$route.name == "Partner") {
                    this.$router.push({ name: 'Partner', params: { id: result.id } })
                }
                else {
                    this.$router.push({ name: 'Product', params: { id: result.id } })
                }
            },
            onChange() {
                this.$emit('input', this.search);

                if (this.isAsync) {
                    this.isLoading = true;
                } else {
                    this.filterResults();
                    this.isOpen = true;
                }
            },
            onArrowDown() {
                if (this.arrowCounter < this.results.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                }
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                }
            },
            onEnter() {
                this.search = this.results[this.arrowCounter].name;
                this.$router.push({ name: 'Product', params: { id: this.results[this.arrowCounter].id } })
                this.arrowCounter = -1;
                this.isOpen = false;
                this.onCloseButton();
            },
            handleClickOutside(event) {
                if (!this.$el.contains(event.target)) {
                    this.arrowCounter = -1;
                    this.isOpen = false;
                }
            }
        },
        watch: {
            items: function (value, oldValue) {
                //if (this.isAsync) {
                //    this.results = value;
                //    this.isOpen = true;
                //    this.isLoading = false;
                //}
                if (value.length !== oldValue.length)
                {
                    this.results = value;
                    this.isLoading = false;
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside);
            this.getCategory();
            //this.items = [{ 'name': 'Banana', 'id': 3 }, { 'name': 'Grape', 'id': 4 }, { 'name': 'Pineapple', 'id': 5 }];
            //this.items = ['Apple','Banana','Orange','Mango','Pear','Peach','Grape','Tangerine','Pineapple'];
            this.timer = setTimeout(() => {
                this.$refs.searchInput.focus();
            }, 200);

        },
        unmounted() {
            document.removeEventListener('click', this.handleClickOutside);
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .search-notes {
        position:absolute;
        top:70px;
        left:0px;
        opacity:0.6;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #017f8a;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ffc500;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #c79b06;
        }

    /* Track */
    .green-bg-text::-webkit-scrollbar-track {
        background: #017f8a;
    }

    /* Handle */
    .green-bg-text::-webkit-scrollbar-thumb {
        background: #ffffff;
    }

        /* Handle on hover */
        .green-bg-text::-webkit-scrollbar-thumb:hover {
            background: #017f8a;
        }
    /*
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }*/
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
    .green-bg-text ul {
        background-color: #017f8a;
        position:relative;
        z-index:9999;
    }

    .autocomplete {
        position: relative;
    }

    .autocomplete-results {
        padding: 0;
        margin: 0;
        /* border: 1px solid #eeeeee; */
        max-height: 320px;
        /*overflow: auto;*/
        overflow-y:scroll;
        overflow-x:hidden;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
        /*font-family: Abril Fatface,cursive;*/
        font-family: Ubuntu;
        font-size: 1.5em;
        text-transform:uppercase;
    }

        .autocomplete-result.is-active,
        .autocomplete-result:hover {
            background-color: #4AAE9B;
            color: white;
        }
    /*search*/
    .search-close {
        right: -14px !important;
    }

    .search-frame {
        background-color: #017f8a;
        position: fixed;
        z-index: 10;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .search-frame-buttons {
        position: absolute;
        top: 45%;
        margin-top: -115px;
        color: white;
        width: 100%;
    }

    .search-buttons {
        width: 50%;
        margin: 0 auto;
        position: relative;
    }

    .search-input {
        background-color: transparent;
        border: 0;
        width: 100%;
        border-bottom: 1px solid #049ca4;
        margin-bottom: 5px;
        color: white;
        font-size: 2em;
        font-family: Ubuntu;
        /*font-family: cursive;*/
        padding-bottom: 15px;
        text-transform:uppercase;
    }

        .search-input:focus {
            outline: none;
        }

    .search-frame ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #049ca4;
        opacity: 1; /* Firefox */
        letter-spacing: 4px;
    }

    .search-frame :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #049ca4;
        letter-spacing: 4px;
    }

    .search-frame ::-ms-input-placeholder { /* Microsoft Edge */
        color: #049ca4;
        letter-spacing: 4px;
    }
    /*search*/
    @media screen and (max-width: 580px) {
        .search-frame-buttons {
            top: 250px;
        }
    }

</style>
